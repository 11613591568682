import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Helmet from "react-helmet"

export default class PageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      intervalId: null,
    }
  }

  componentDidMount() {
    const intervalId = setInterval(() => this.forceUpdate(), 10000)
    this.setState({
      intervalId,
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  emptyPost = url =>
    fetch(url, {
      method: "post",
    }).then(() => {
      setTimeout(() => this.forceUpdate(), 2000)
    })

  render() {
    return (
      <Layout currentPath={null} lang={null} pathEn={null} pathEs={null}>
        <Helmet>
          <title>Admin</title>
        </Helmet>
        <Root>
          <Header>
            <p>
              After publishing changes in wordpress, please start a deployment
              of the desired environment, staging for testing, or production for
              the live site. After clicking 'Deploy', reload the page in order
              to see the state of the deployment. Please note, that the
              deployment might take some minutes to start, especially if one
              deployment is already running on one environemnt and you want to
              start a deployment on another environment. Patience (:
            </p>
            <p>
              Click on the names below, Production or Staging to open the site
              in a new tab.
            </p>
          </Header>
          <Env>
            <Title href="https://alpujarra.dinoalastorres.org/" target="_blank">
              Production
            </Title>
            <Badge
              href="https://app.netlify.com/sites/optimistic-meninsky-1ad7c9/deploys"
              target="_blank"
            >
              <Img
                alt="production deploy status badge from netlify"
                src={`https://api.netlify.com/api/v1/badges/18384b59-b7e7-4377-93b1-3462aca668ee/deploy-status#${Date.now()}`}
              />
            </Badge>
            <Button
              onClick={() => {
                this.emptyPost(
                  "https://api.netlify.com/build_hooks/5d9e17d326ac4e950a493d3b"
                )
              }}
            >
              Deploy Production
            </Button>
          </Env>
          <Env>

            <Title href="https://torres.quepasaorgiva.com/" target="_blank">
              Staging
            </Title>
            <Badge
              href="https://app.netlify.com/sites/relaxed-austin-fcc550/deploys"
              target="_blank"
            >
              <Img
                alt="staging deploy status badge from netlify"
                src={`https://api.netlify.com/api/v1/badges/966edcbf-285b-4134-a2f8-95a7a15fd8b1/deploy-status#${Date.now()}`}
              />
            </Badge>
            <Button
              onClick={() => {
                this.emptyPost(
                  "https://api.netlify.com/build_hooks/5d9dee479f98250c809d52e5"
                )
              }}
            >
              Deploy Staging
            </Button>
          </Env>
        </Root>
      </Layout>
    )
  }
}

const Header = styled.header`
  font-size: 18px;
  padding-bottom: 48px;
`

const Img = styled.img`
  margin: 0;
`

const Root = styled.div``

const Title = styled.a``

const Badge = styled.a``

const Button = styled.button``

const Env = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  > * {
    margin-right: 24px;
  }
`
